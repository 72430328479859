const node2List = (node, lvl) => {
    var list = [];
    var l = lvl;
    node.Level = l;
    list.push(node);
    if (node.Children.length > 0) {
        node.Children.forEach((c) => {
            list.push(...node2List(c, l + 1));
        });
    }
    return list;
};

export const tree2List = (treeData) => {
    var list = [];
    list.push({HospID:-1,HospName:"顶级机构"})
    treeData.forEach((element) => {
        list.push(...node2List(element, -1));
    });
    return list;
};
