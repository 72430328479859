<template>
  <q-card flat>
    <q-item>
      <q-item-section avatar>
        <q-avatar>
          <q-icon
            class="glossy"
            color="primary"
            size="30px"
            name="location_city"
          />
        </q-avatar>
      </q-item-section>

      <q-item-section>
        <q-item-label>机构设置</q-item-label>
        <q-item-label caption> 管理维护机构信息 </q-item-label>
      </q-item-section>
      <q-item-section side>
        <div class="row">
          <q-btn
            dense
            class="q-mr-md"
            color="primary"
            icon="note_add"
            @click="onAdd"
            >新增</q-btn
          >
          <q-btn
            dense
            color="secondary"
            :icon="readOnly ? 'border_color' : 'save'"
            @click="readOnly ? onEdit() : onSave()"
            >{{ readOnly ? "修改" : "保存" }}</q-btn
          >
        </div>
      </q-item-section>
    </q-item>
    <q-separator />
    <q-card-section>
      <q-splitter v-model="splitModel">
        <template v-slot:before>
          <q-tree
            ref="domTree"
            :nodes="treeData"
            node-key="HospID"
            label-key="HospName"
            children-key="Children"
            no-connectors
            selected-color="primary"
            v-model:selected="selectedNode"
            default-expand-all
            @update:selected="onNodeSelected"
          >
          </q-tree>
        </template>

        <template v-slot:after>
          <q-card>
            <q-tabs
              v-model="tab"
              dense
              class="text-grey"
              active-color="primary"
              indicator-color="primary"
              align="left"
              narrow-indicator
            >
              <q-tab name="basic" label="基本信息" />
              <q-tab name="option" label="机构选项" />
            </q-tabs>
            <q-separator />

            <q-tab-panels v-model="tab" animated>
              <q-tab-panel name="basic">
                <div class="q-pa-md" v-if="currentHosp">
                  <q-form class="q-gutter-md">
                    <q-select
                      label="所属机构"
                      :readonly="readOnly"
                      :filled="readOnly"
                      :options="treeList"
                      option-value="HospID"
                      :option-label="(v) => `[${v.HospID}] ${v.HospName}`"
                      options-dense
                      v-model="currentHosp.ParentID"
                      map-options
                      emit-value
                    >
                    </q-select>
                    <q-input
                      label="机构ID *"
                      v-model="currentHosp.HospID"
                      type="number"
                      hint="医疗机构ID"
                      fill-input
                      lazy-rules
                      :filled="readOnly"
                      :readonly="readOnly"
                      :rules="[
                        (val) =>
                          val > 0 || '请输入医疗机构ID,(唯一标识当前的机构)',
                      ]"
                    />
                    <q-input
                      label="机构编码 *"
                      v-model="currentHosp.HospNo"
                      type="number"
                      hint="医疗机构的编码"
                      :readonly="readOnly"
                      :filled="readOnly"
                      lazy-rules
                      :rules="[(val) => val > 0 || '请输入医疗机构的编码']"
                    />

                    <q-input
                      label="机构名称"
                      v-model="currentHosp.HospName"
                      lazy-rules
                      :readonly="readOnly"
                      :filled="readOnly"
                      :rules="[
                        (val) =>
                          (val !== null && val !== '') || '请输入机构名称',
                      ]"
                    />

                    <q-input
                      label="医保编号 *"
                      v-model="currentHosp.HospMICode"
                      hint="医疗机构的医保编码"
                      lazy-rules
                      :filled="readOnly"
                      :readonly="readOnly"
                      :rules="[
                        (val) =>
                          (val !== null && val !== '') ||
                          '请输入医疗机构的医保编码',
                      ]"
                    />

                    <q-input
                      label="行政区划 *"
                      v-model="currentHosp.HospAreaCode"
                      hint="医疗机构的行政区划"
                      lazy-rules
                      :filled="readOnly"
                      :readonly="readOnly"
                      :rules="[
                        (val) =>
                          (val !== null && val !== '') ||
                          '请输入医疗机构的行政区划',
                      ]"
                    />
                  </q-form>
                </div>
              </q-tab-panel>
              <q-tab-panel name="option">
                <HospOptions :HospID="currentHosp.HospID" :tabOption="tab" />
              </q-tab-panel>
            </q-tab-panels>
          </q-card>
        </template>
      </q-splitter>
      <q-inner-loading :showing="loading">
        <q-spinner-hourglass size="50" color="primary" />
      </q-inner-loading>
    </q-card-section>
  </q-card>
</template>

<script>
import {
  GettGY_Base_HospBasicInfoTreeList,
  GettGY_Base_HospBasicInfoEntity,
  CreatetGY_Base_HospBasicInfoEntity,
  UpdatetGY_Base_HospBasicInfoEntity,
} from "@/api/gy/base/hosp";
import { defineComponent, ref, onMounted, nextTick } from "vue";

import { tree2List } from "./js/treeUtil";
import HospOptions from "./hospOptions";
import msg from "@/config/notify";
const treeUtil = () => {
  const loading = ref(false);

  const domTree = ref(null);
  const treeData = ref([]);
  const treeList = ref([]);
  const selectedNode = ref(0);
  var currentHosp = ref(null);
  const readOnly = ref(true);
  const onloadData = () => {
    loading.value = true;
    GettGY_Base_HospBasicInfoTreeList({})
      .then((res) => {
        if (res.Code == 0) {
          treeData.value = res.Data;
          treeList.value = tree2List(res.Data);
          nextTick(() => {
            domTree.value.expandAll();
          });
        } else {
          msg.error(res.Message);
        }
      })
      .finally(() => {
        loading.value = false;
      });
  };

  onMounted(() => {
    onloadData();
  });

  const onNodeSelected = (tag) => {
    GettGY_Base_HospBasicInfoEntity({ HospID: tag }).then((res) => {
      if (res.Code == 0) {
        currentHosp.value = res.Data;
        readOnly.value = true;
      } else {
        msg.error(res.Message);
      }
    });
  };

  const onAdd = () => {
    CreatetGY_Base_HospBasicInfoEntity({}).then((res) => {
      if (res.Code == 0) {
        currentHosp.value = res.Data;
      } else {
        msg.error(res.Message);
      }
    });
    readOnly.value = false;
  };
  const onEdit = () => {
    if (currentHosp.value) readOnly.value = false;
    else msg.info("请先选择要修改的机构");
  };

  const onSave = () => {
    UpdatetGY_Base_HospBasicInfoEntity(currentHosp.value).then((res) => {
      if (res.Code == 0) {
        readOnly.value = true;
        nextTick(() => {
          onloadData();
        });
      } else {
        msg.error(res.Message);
      }
    });
  };

  return {
    loading,
    readOnly,
    domTree,
    treeData,
    treeList,
    selectedNode,
    onloadData,
    onNodeSelected,
    currentHosp,
    onAdd,
    onEdit,
    onSave,
  };
};

export default defineComponent({
  components: { HospOptions },
  setup() {
    const splitModel = ref(30);

    return {
      ...treeUtil(),
      splitModel,
      tab: ref("basic"),
    };
  },
});
</script>
