<template>
  <div>
    <!-- {{ currentHospID }} -->
     <q-btn
            dense
            class="q-mr-md"
            color="primary"
            icon="note_add"
            style="margin-bottom: 10px"
              @click="AddDialog = true"
            >新增</q-btn
          >
    <q-table
      :rows="rows"
      :columns="columns"
      :rows-per-page-options="[]"
      row-key="name"
    >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="OptionName" :props="props">
            {{ props.row.OptionName }}
          </q-td>
          <q-td key="OptionValue" :props="props">
            {{ props.row.OptionValue }}
            <q-popup-edit
              @save="save"
              @update:model-value="OnEdit(props.row)"
              v-model="props.row.OptionValue"
              buttons
              v-slot="scope"
            >
              <q-input
                v-model="scope.value"
                dense
                autofocus
                counter
                @keyup.enter="scope.set"
              />
            </q-popup-edit>
          </q-td>
          <q-td key="Remark" :props="props">
            {{ props.row.Remark }}
          </q-td>
          <q-td key="Operate" :props="props">
                <q-btn
            dense
            color="primary"
             size="sm"
              @click="Del(props.row, props.rowIndex)"
            >删除</q-btn
          >
       

          </q-td>
        </q-tr>
      </template>
    </q-table>
    <q-dialog v-model="AddDialog">
      <q-card style="width: 400px; max-width: 80vw">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h8">新增选项</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section>
          <div class="q-gutter-md">
            <q-input
              ref="OptionNameRef"
              filled
              v-model="AddModel.OptionName"
              label="选项名称"
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || '不能为空']"
            />

            <q-input
              ref="OptionValueRef"
              filled
              v-model="AddModel.OptionValue"
              label="值"
              lazy-rules
              :rules="[(val) => (val && val.length > 0) || '不能为空']"
            />
            <q-input v-model="AddModel.Remark"  label="备注" filled type="textarea" />
            <div>
              <q-btn
                label="确定"
                type="submit"
                color="primary"
                @click="onAdd"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import {
  ref,
  toRefs,
  watch,
  onMounted,
  reactive,
} from "vue";

import {
  GettGY_Base_SysOptionList,
  UpdatetGY_Base_SysOptionEntity,
  DeletetGY_Base_SysOptionEntity,
  CreatetGY_Base_SysOptionEntity,
} from "@/api/gy/base/sysoption";

import msg from '@/config/notify'
import dlg from '@/config/dialog'
const columns = [
  {
    name: "OptionName",
    align: "left",
    label: "选项名称",
    field: "OptionName",
  },
  {
    name: "OptionValue",
    align: "left",
    label: "值",
    field: "OptionValue",
  },
  {
    name: "Remark",
    align: "left",
    label: "备注",
    field: "Remark",
  },
  {
    name: "Operate",
    align: "center",
    label: "操作",
    field: "Operate",
  },
];

export default {
  props: ["HospID"],
  setup(props) {
    const OptionNameRef = ref(null);
    const OptionValueRef = ref(null);
    const { HospID } = toRefs(props);
    var rows = ref([]);
    var AddDialog = ref(false);
    var NewValue = ref("");
    const AddModel = reactive({
      HospID: HospID.value,
      OptionName: "",
      OptionValue: "",
      Remark: "",
    });
    watch(HospID, (newValue) => {
      console.log("newValue", newValue);
      HospID.value = newValue;
      OnLoadData();
    });
    onMounted(() => {
      OnLoadData();
    });
    const OnLoadData = () => {
      GettGY_Base_SysOptionList({ HospID: HospID.value })
        .then((res) => {
          if (res.Code == 0) {
            console.log(res.Data);
            rows.value = res.Data;
          } else {
            msg.error(res.Message);
          }
        })
        .catch((res) => {
          msg.error(res.Message);
        });
    };
    const Del = (row, index) => {
      dlg.confirm("确定删除吗?", { title: "系统提示" }).onOk(() => {
        DeletetGY_Base_SysOptionEntity(row)
          .then((res) => {
            if (res.Code == 0) {
              rows.value.splice(index, 1);
            } else {
              msg.error(res.Message);
            }
          })
          .catch((res) => {
            msg.error(res.Message);
          });
      });
    };

    const onAdd = () => {
      OptionNameRef.value.validate();
      OptionValueRef.value.validate();
      if (OptionNameRef.value.hasError || OptionValueRef.value.hasError) return;
      CreatetGY_Base_SysOptionEntity().then((res) => {
        AddModel.OptionID = res.Data.OptionID;
        update(AddModel);
      });
    };

    const save = (value) => {
      NewValue.value = value;
    };
    const OnEdit = (model) => {
      model.OptionValue = NewValue.value;
      update(model);
    };
    const update = (model) => {
      UpdatetGY_Base_SysOptionEntity(model)
        .then((res) => {
          if (res.Code == 0) {
            if (AddDialog.value) {
              AddDialog.value = false;
              OnLoadData();
            }
            msg.success(res.Message);
            console.log(res.Data);
          } else {
            msg.error(res.Message);
          }
        })
        .catch((res) => {
          msg.error(res.Message);
        });
    };
    return {
      rows,
      columns,
      save,
      update,
      currentHospID: HospID.value,
      Del,
      AddDialog,
      AddModel,
      onAdd,
      OptionNameRef,
      OptionValueRef,
      OnEdit,
    };
  },
};
</script>