import { axios } from '@/utils/request' //'../utils/request'

const api = {
    flod: '/tGY_Base_SysOption'
}

export default api

/// <summary>
/// 获取系统选项列表
/// </summary>
/// <param name="model"></param>
/// <returns></returns>
export function GettGY_Base_SysOptionList(parameter) {
    return axios({
        url: api.flod + '/GettGY_Base_SysOptionList',
        method: 'post',
        data: parameter
    })
}
export function UpdatetGY_Base_SysOptionEntity(parameter) {
    return axios({
        url: api.flod + '/UpdatetGY_Base_SysOptionEntity',
        method: 'post',
        data: parameter
    })
}
export function DeletetGY_Base_SysOptionEntity(parameter) {
    return axios({
        url: api.flod + '/DeletetGY_Base_SysOptionEntity',
        method: 'post',
        data: parameter
    })
}
export function CreatetGY_Base_SysOptionEntity(parameter) {
    return axios({
        url: api.flod + '/CreatetGY_Base_SysOptionEntity',
        method: 'post',
        data: parameter
    })
}